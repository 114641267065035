import React, { useState } from "react";
import { QrCodeIcon, CameraIcon, ReceiptPercentIcon } from "@heroicons/react/24/solid";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import { IconModal } from "./IconModal";
import CardModal from "./CardModal";

function DashboardSection(props) {
  const [modalContent, setModalContent] = useState(null); // State to control which icon modal to open
  const [cardModalContent, setCardModalContent] = useState(null); // State to control card modal content
  const [scanResult, setScanResult] = useState(null); // State to hold scan result
  const auth = useAuth(); // Access the auth object for the UUID

  const handleOpenIconModal = (content) => {
    setModalContent(content); // Set content for the icon modal
    setScanResult(null); // Reset scan result on modal open
  };

  const handleOpenCardModal = (cardContent) => {
    setCardModalContent(cardContent); // Set content for the card modal
  };

  const handleCloseModal = () => {
    setModalContent(null); // Close modals
    setCardModalContent(null); // Close card modal
    setScanResult(null); // Reset scan result
  };

  const handleScan = (data) => {
    if (data) {
      setScanResult(data); // Set scan result if a QR code is scanned
    }
  };

  const handleError = (err) => {
    console.error(err); // Optional: Log errors
  };

  const cards = [
    {
      title: "Crescent Café",
      description: "A cozy café serving the best organic coffee and pastries.",
      images: [
        "https://via.placeholder.com/300x200?text=Crescent+1",
        "https://via.placeholder.com/300x200?text=Crescent+2",
        "https://via.placeholder.com/300x200?text=Crescent+3",
      ],
      latitude: 40.712776, // Example: New York City
      longitude: -74.005974,
      hours: [
        { day: 'Monday', open: '8:00 AM', close: '6:00 PM' },
        { day: 'Tuesday', open: '8:00 AM', close: '6:00 PM' },
        { day: 'Wednesday', open: '8:00 AM', close: '6:00 PM' },
        { day: 'Thursday', open: '8:00 AM', close: '6:00 PM' },
        { day: 'Friday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Saturday', open: '9:00 AM', close: '8:00 PM' },
        { day: 'Sunday', open: null, close: null }, // Closed on Sunday
      ],
      contact: {
        phone: "+1-555-123-4567",
        website: "https://www.crescentcafe.com",
        facebook: "https://www.facebook.com/crescentcafe",
        instagram: "https://www.instagram.com/crescentcafe",
        twitter: "https://www.twitter.com/crescentcafe",
      }
    },
    {
      title: "Halal Butcher",
      description: "Providing fresh, high-quality halal meats since 1990.",
      images: [
        "https://via.placeholder.com/300x200?text=Butcher+1",
        "https://via.placeholder.com/300x200?text=Butcher+2",
        "https://via.placeholder.com/300x200?text=Butcher+3",
      ],
      latitude: 34.052235, // Example: Los Angeles
      longitude: -118.243683,
      hours: [
        { day: 'Monday', open: '9:00 AM', close: '7:00 PM' },
        { day: 'Tuesday', open: '9:00 AM', close: '7:00 PM' },
        { day: 'Wednesday', open: '9:00 AM', close: '7:00 PM' },
        { day: 'Thursday', open: '9:00 AM', close: '7:00 PM' },
        { day: 'Friday', open: '9:00 AM', close: '8:00 PM' },
        { day: 'Saturday', open: '9:00 AM', close: '8:00 PM' },
        { day: 'Sunday', open: null, close: null }, // Closed on Sunday
      ],
      contact: {
        phone: "+1-555-987-6543",
        website: "https://www.halalbutcher.com",
        facebook: "https://www.facebook.com/halalbutcher",
        instagram: "https://www.instagram.com/halalbutcher",
        twitter: "https://www.twitter.com/halalbutcher",
      }
    },
    {
      title: "Saffron Kitchen",
      description: "Authentic Mediterranean cuisine with a modern twist.",
      images: [
        "https://via.placeholder.com/300x200?text=Saffron+1",
        "https://via.placeholder.com/300x200?text=Saffron+2",
        "https://via.placeholder.com/300x200?text=Saffron+3",
      ],
      latitude: 51.507351, // Example: London
      longitude: -0.127758,
      hours: [
        { day: 'Monday', open: '11:00 AM', close: '10:00 PM' },
        { day: 'Tuesday', open: '11:00 AM', close: '10:00 PM' },
        { day: 'Wednesday', open: '11:00 AM', close: '10:00 PM' },
        { day: 'Thursday', open: '11:00 AM', close: '10:00 PM' },
        { day: 'Friday', open: '11:00 AM', close: '11:00 PM' },
        { day: 'Saturday', open: '11:00 AM', close: '11:00 PM' },
        { day: 'Sunday', open: '12:00 PM', close: '9:00 PM' },
      ],
      contact: {
        phone: "+44-20-1234-5678",
        website: "https://www.saffronkitchen.co.uk",
        facebook: "https://www.facebook.com/saffronkitchen",
        instagram: "https://www.instagram.com/saffronkitchen",
        twitter: "https://www.twitter.com/saffronkitchen",
      }
    },
    {
      title: "Muslim Apparel",
      description: "Stylish and modest clothing for all occasions.",
      images: [
        "https://via.placeholder.com/300x200?text=Apparel+1",
        "https://via.placeholder.com/300x200?text=Apparel+2",
        "https://via.placeholder.com/300x200?text=Apparel+3",
      ],
      latitude: 48.856613, // Example: Paris
      longitude: 2.352222,
      hours: [
        { day: 'Monday', open: '10:00 AM', close: '8:00 PM' },
        { day: 'Tuesday', open: '10:00 AM', close: '8:00 PM' },
        { day: 'Wednesday', open: '10:00 AM', close: '8:00 PM' },
        { day: 'Thursday', open: '10:00 AM', close: '8:00 PM' },
        { day: 'Friday', open: '10:00 AM', close: '9:00 PM' },
        { day: 'Saturday', open: '11:00 AM', close: '9:00 PM' },
        { day: 'Sunday', open: null, close: null }, // Closed on Sunday
      ],
      contact: {
        phone: "+33-1-2345-6789",
        website: "https://www.muslimapparel.fr",
        facebook: "https://www.facebook.com/muslimapparel",
        instagram: "https://www.instagram.com/muslimapparel",
        twitter: "https://www.twitter.com/muslimapparel",
      }
    },
    {
      title: "Green Grocer",
      description: "Local grocery store offering fresh fruits, vegetables, and spices.",
      images: [
        "https://via.placeholder.com/300x200?text=Grocer+1",
        "https://via.placeholder.com/300x200?text=Grocer+2",
        "https://via.placeholder.com/300x200?text=Grocer+3",
      ],
      latitude: 35.689487, // Example: Tokyo
      longitude: 139.691711,
      hours: [
        { day: 'Monday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Tuesday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Wednesday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Thursday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Friday', open: '8:00 AM', close: '9:00 PM' },
        { day: 'Saturday', open: '8:00 AM', close: '9:00 PM' },
        { day: 'Sunday', open: null, close: null }, // Closed on Sunday
      ],
      contact: {
        phone: "+81-3-1234-5678",
        website: "https://www.greengrocer.jp",
        facebook: "https://www.facebook.com/greengrocer",
        instagram: "https://www.instagram.com/greengrocer",
        twitter: "https://www.twitter.com/greengrocer",
      }
    },
    {
      title: "Sunset Bakery",
      description: "Delicious homemade breads, cakes, and desserts.",
      images: [
        "https://via.placeholder.com/300x200?text=Bakery+1",
        "https://via.placeholder.com/300x200?text=Bakery+2",
        "https://via.placeholder.com/300x200?text=Bakery+3",
      ],
      latitude: 41.902782, // Example: Rome
      longitude: 12.496365,
      hours: [
        { day: 'Monday', open: '7:00 AM', close: '7:00 PM' },
        { day: 'Tuesday', open: '7:00 AM', close: '7:00 PM' },
        { day: 'Wednesday', open: '7:00 AM', close: '7:00 PM' },
        { day: 'Thursday', open: '7:00 AM', close: '7:00 PM' },
        { day: 'Friday', open: '7:00 AM', close: '8:00 PM' },
        { day: 'Saturday', open: '8:00 AM', close: '8:00 PM' },
        { day: 'Sunday', open: null, close: null }, // Closed on Sunday
      ],
      contact: {
        phone: "+39-06-1234-5678",
        website: "https://www.sunsetbakery.it",
        facebook: "https://www.facebook.com/sunsetbakery",
        instagram: "https://www.instagram.com/sunsetbakery",
        twitter: "https://www.twitter.com/sunsetbakery",
      }
    },
  ];

  return (
    <>
      <img className="w-full h-96 lg:w-96 lg:h-auto relative object-cover mx-auto" src="/CRESCENT_CONVENIENCE.png" alt="" />
      <div className="container text-center">
        <SectionHeader title="Welcome 👋" subtitle="Explore local businesses or use your special code below" strapline={props.strapline} />

        {/* Icons Container */}
        <div className="flex flex-row items-center justify-center space-x-6 py-8">
          <div onClick={() => handleOpenIconModal("QR Code")} className="cursor-pointer flex flex-col items-center">
            <QrCodeIcon className="w-12 h-12 text-customBeige-300" />
            <p className="mt-2">QR Code</p>
          </div>
          <div onClick={() => handleOpenIconModal("Scanner")} className="cursor-pointer flex flex-col items-center">
            <CameraIcon className="w-12 h-12 text-customBeige-300" />
            <p className="mt-2">Scanner</p>
          </div>
          <div onClick={() => handleOpenIconModal("Receipt")} className="cursor-pointer flex flex-col items-center">
            <ReceiptPercentIcon className="w-12 h-12 text-customBeige-300" />
            <p className="mt-2">Receipt</p>
          </div>
        </div>

        {/* Responsive Grid Layout with Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-8">
          {cards.map((card, index) => (
            <div key={index} onClick={() => handleOpenCardModal(card)} className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer">
              <img src={card.image} alt={card.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{card.title}</h3>
                <p className="text-gray-700">{card.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Icon Modal */}
        {modalContent && (
          <IconModal
            modalContent={modalContent}
            handleError={handleError}
            handleScan={handleScan}
            scanResult={scanResult}
            handleCloseModal={handleCloseModal}
          />
        )}

        {/* Card Modal */}
        {cardModalContent && (
          <CardModal
            cardContent={cardModalContent}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </>
  );
}

export default DashboardSection;
