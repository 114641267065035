import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function HeroSection(props) {
  return (
    <div className="container flex flex-col lg:flex-row-reverse space-y-3 lg:space-y-0 text-center mt-10 lg:text-left">
      {/* Image will appear first on mobile and second on large screens */}
      <div className="lg:w-1/2 lg:mr-16 lg:flex lg:justify-center lg:items-center pb-12 md:pb-0 order-1 lg:order-2">
        <div className="relative">
          <div className="hidden sm:block">
            <div className="absolute pattern-dots text-blue-100 top-0 left-0 w-32 h-48 md:h-96 transform -translate-y-12 -translate-x-16 -rotate-3" />
            <div className="absolute pattern-dots text-blue-100 bottom-0 right-0 w-32 h-48 md:h-96 transform translate-y-12 translate-x-16 rotate-3" />
            <div className="absolute rounded-xl bottom-0 left-0 w-32 h-32 bg-blue-200 bg-opacity-50 -mb-10 -ml-10 transform rotate-3" />
          </div>
          <img
            className="w-full h-96 lg:w-96 lg:h-auto relative object-cover rounded-lg mx-auto shadow-lg"
            src="/CRESCENT_CONVENIENCE.png"
            alt=""
          />
        </div>
      </div>

      {/* Text and buttons will appear second on mobile and first on large screens */}
      <div className="lg:w-1/2 lg:self-center order-2 lg:order-1">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
        />
        <div className="flex flex-col sm:flex-row sm:items-center justify-center lg:justify-start space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
          <Button
            component={Link}
            to="/pricing"
            size="xl"
            endIcon={
              <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
            }
          >
            Get your discount code now
          </Button>
          <Button component={Link} to="/about" size="xl" variant="light">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
