import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import OpeningTimes from "./OpeningTimes"; // Import the OpeningTimes component
import { SocialMediaIcons } from "./SocialMediaIcons"; // Import the SocialMediaIcons component

const CardModal = ({ cardContent, handleCloseModal }) => {
  const businessLocation = { lat: cardContent.latitude, lng: cardContent.longitude }; // Business location coordinates

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  // Slider settings for responsiveness
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true, // Adjust height based on the slide
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="relative bg-white w-full h-full md:max-w-lg rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-col h-full">
          {/* Sticky Map section */}
          <div className="w-full h-48 md:h-64 mb-4 sticky top-0 z-10"> {/* Map remains sticky */}
            <LoadScript googleMapsApiKey="AIzaSyBrkNU-_UvXdpOQW7ZIhqj83BC1J3XtHv4"> {/* Replace with your actual API key */}
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={businessLocation}
                zoom={16}
              >
                <Marker position={businessLocation} />
              </GoogleMap>
            </LoadScript>
          </div>

          <div className="p-4 flex-grow flex flex-col overflow-y-auto overflow-x-hidden"> {/* Prevent horizontal overflow */}
            <h2 className="text-2xl text-black mb-4 font-bold">{cardContent.title}</h2>
            <p className="text-lg text-gray-700 mb-6">{cardContent.description}</p>
            <SocialMediaIcons contact={cardContent.contact} />

            {/* Image carousel using React Slick */}
            <div className="mb-6">
              <Slider {...sliderSettings}>
                {cardContent.images.map((image, index) => (
                  <div key={index}>
                    <img className="w-full h-48 object-cover rounded-md" src={image} alt={`Slide ${index + 1}`} />
                  </div>
                ))}
              </Slider>
            </div>

            {/* Business Description Section */}
            <div className="pt-3">
              <div className="border-t border-gray-200 my-4"></div> {/* Divider */}
              <div className="bg-gray-100 p-4 rounded-md shadow-sm"> {/* Styled container */}
                <h3 className="text-xl font-semibold text-gray-800 mb-2">About {cardContent.title}</h3>
                <p className="text-base text-gray-600 mb-4 leading-relaxed">
                  {cardContent.title} is a {cardContent.description.toLowerCase()}. Located at a prime spot in the city, this business offers exceptional services/products that cater to the needs of locals and visitors alike. Whether you're looking for {cardContent.title.includes("Café") ? "a relaxing place to enjoy coffee" : "fresh products, high-quality clothing, or delicious food"}, {cardContent.title} has got you covered. With friendly staff, a welcoming environment, and a commitment to excellence, this place is a must-visit for anyone in the area.
                </p>
              </div>
            </div>

            {/* Opening Times Section */}
            <div className="pt-3">
              <OpeningTimes hours={cardContent.hours} />
            </div>

            {/* Sticky button at the bottom of the modal */}
            <button 
              onClick={handleCloseModal} 
              className="mt-4 bg-customBeige px-4 py-2 rounded text-sm md:text-base"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardModal;
