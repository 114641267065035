import React from 'react';

const OpeningTimes = ({ hours }) => {
  const formatHours = (hours) => {
    return hours?.map(({ day, open, close }, index) => (
      <div key={index} className="flex justify-between py-2 text-sm">
        <span className="text-gray-700 font-medium">{day}</span>
        <span className="text-gray-500">{open ? `${open} - ${close}` : 'Closed'}</span>
      </div>
    ));
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg mx-3 my-2">
      <h2 className="text-sm font-semibold mb-3 text-gray-800">Opening Times</h2>
      {formatHours(hours)}
    </div>
  );
};

export default OpeningTimes;
