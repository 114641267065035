import React from "react";
import { QRCodeSVG } from "qrcode.react"; // Import the correct QR code component
import QrScanner from "react-qr-scanner"; // Import the QR scanner
import { useAuth } from "./../util/auth";

export const IconModal = ({
  modalContent,
  handleError,
  handleScan,
  scanResult,
  handleCloseModal,
}) => {
  const auth = useAuth(); // Access the auth object for the UUID

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
        <div
          className="relative bg-center w-full h-full md:w-auto md:h-auto md:max-w-lg rounded-lg overflow-hidden"
          style={{
            backgroundImage: "url('/background.png')",
          }}
        >
          <div className=" w-full h-full flex flex-col justify-between p-8">
            <h2 className="text-2xl text-black mb-4"></h2>

            {/* Show QR code if QR Code icon was clicked */}
            {modalContent === "QR Code" && (
              <>
                <h2 className="text-2xl text-black">
                  Show this QR Code at checkout for your exclusive discount! 🎉{" "}
                </h2>
                <div className="flex justify-center mb-4">
                  <QRCodeSVG value={auth.user.id} size={200} />
                  {/* Generates the QR Code */}
                </div>
              </>
            )}

            {/* Show QR scanner if Scanner icon was clicked */}
            {modalContent === "Scanner" && (
              <div className="flex justify-center items-center flex-grow mb-4">
                <QrScanner
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%", height: "auto" }}
                  constraints={{
                    video: {
                      facingMode: "environment", // Request the rear camera
                    },
                  }}
                />
              </div>
            )}

            {/* Display scan result if available */}
            {scanResult && (
              <div className="mt-4 text-lg text-blue-200">
                <p>Scan Result: {scanResult}</p>
              </div>
            )}

            {modalContent === "Receipt" && (
              <div className="flex justify-center items-center flex-grow mb-4">
                <QrScanner
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: "100%", height: "auto" }}
                  constraints={{
                    video: {
                      facingMode: "environment", // Request the rear camera
                    },
                  }}
                />
              </div>
            )}

            {/* Sticky button at the bottom of the modal */}
            <button
              onClick={handleCloseModal}
              className="mt-6 bg-customBeige px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
